import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: "/groups",
    meta: {
      requiresAuth: true
    },
    component: () => import("@/views"),
    children: [
      {
        path: "groups",
        name: "Groups",
        meta: {
          requiresAuth: true
        },
            component: () => import("@/views/groups")
      },
      {
        path: "groups/:groupId",
        name: "GroupSingle",
        meta: {
          requiresAuth: true
        },
        component: () => import("@/views/groups/Id")
      },
      {
        path: "profile",
        name: "Profile",
        meta: {
          requiresAuth: true
        },
        component: () => import("@/views/profile")
      }
    ]
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404")
  },
  {
    path: "/public/groups/:groupId/events/:eventId",
    name: "PublicEvent",
    component: () => import("@/views/public/groups/events")
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () => import("@/views/entry/signIn")
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/entry/signUp")
  },
  {
    path: "/recovery",
    name: "recovery",
    component: () => import("@/views/entry/recovery")
  },
  {
    path: "/recovery/error",
    name: "recoveryError",
    component: () => import("@/views/entry/recovery/error")
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("@/views/entry/welcome")
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/views/download")
  },
  {
    path: "/entry/signup/verify/:code",
    name: "UserVerify",
    component: () => import("@/views/entry/signUp/verify")
  },
  {
    path: "/entry/signup/confirmation/:email/:send",
    name: "ConfirmationEmail",
    component: () => import("@/views/entry/signUp/confirmation")
  },
  {
    path: "/public/groups/:groupId/:code",
    name: "PublicGroup",
    component: () => import("@/views/public/groups")
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
