// get links from config.js file

let linkLetsChat = "/";
let linkDownloads = "/";
let linkIPhoneApp = "/";
let linkAndroidApp = "/";
let linkCompany = "/";
let linkTermsOfService = "/";
let linkPrivacyPolicy = "/";
let linkAboutUs = "/";
let linkImportMembersTemplate = "/";

if (window.LINK_CONFIG) {
  linkLetsChat = window.LINK_CONFIG.linkLetsChat;
  linkDownloads = window.LINK_CONFIG.linkDownloads;
  linkIPhoneApp = window.LINK_CONFIG.linkIPhoneApp;
  linkAndroidApp = window.LINK_CONFIG.linkAndroidApp;
  linkCompany = window.LINK_CONFIG.linkCompany;
  linkTermsOfService = window.LINK_CONFIG.linkTermsOfService;
  linkPrivacyPolicy = window.LINK_CONFIG.linkPrivacyPolicy;
  linkAboutUs = window.LINK_CONFIG.linkAboutUs;
  linkImportMembersTemplate = window.LINK_CONFIG.linkImportMembersTemplate;
} else {
  console.error("LINK_CONFIG not found");
}

let urlBackend = null;
let urlFrontend = null;

if (window.H_CONFIG) {
  urlBackend = window.H_CONFIG.hosts[window.H_CONFIG.useHostBackend];
  urlFrontend = window.H_CONFIG.hosts[window.H_CONFIG.useHostFrontend];
} else {
  console.error("LINK_CONFIG not found");
}
if (!window.FB_CONFIG) {
  console.error("FB_CONFIG not found");
}

export default {
  // Public URLs
  URL_SIGNIN: urlFrontend + "/#/signin/",
  LINK_LETS_CHAT: linkLetsChat,
  LINK_DOWNLOADS: linkDownloads,
  LINK_ANDROID: linkAndroidApp,
  LINK_IPHONE: linkIPhoneApp,
  LINK_COMPANY: linkCompany,
  LINK_TERMS_OF_SERVICE: linkTermsOfService,
  LINK_PRIVACY_POLICY: linkPrivacyPolicy,
  LINK_ABOUT: linkAboutUs,
  LINK_MEMBERS_TEMPLATE: linkImportMembersTemplate,

  // BASE_URL_FRONTEND: urlFrontend,  // ToDo: Review - this was used for Email verification after signup!?
  BASE_URL: urlBackend,
  BASE_API_URL: urlBackend + "/api",
  // Zoom
  ZOOM_AUTH: "/Zoom/auth",
  ZOOM_DE_AUTH: "/Zoom/deauth",

  // User APIs
  API_USER_PROFILE: "/user/my",
  API_USER_UPDATE: "/user/update",
  API_USER_CREATE: "/user/create",
  API_USER_IMPORT: "/import/users", // ToDo: Fix the Url pattern

  API_NOTIFICATIONS: "/user/Notifications/",
  API_NOTIFICATION_READ: "user/Notifications/Read",

  // Groups APIs
  API_GROUP_ADMIN: "/group/admin/my",
  API_GROUP_ADMIN_SEARCH: "/group/admin/search",

  API_GROUP_CREATE: "/group/create",
  API_GROUP_UPDATE: "/group/update",
  API_GROUP_JOIN: "/group/join/",
  
  // Event APIs
  API_EVENT_INSTANTIATE: "/event/CreateMobile",
  API_EVENT_CREATE: "/event/create",
  API_EVENT_ANSWER: "/Event/Answer/",
  API_EVENT_MEETING: "/Event/Meeting/",
  
  // Post APIs
  API_POST_CREATE: "/post/create",
  API_POST_UPDATE: "/post/update",
};

// ToDo: Consider refactor into Enum Urls + Named Params (See $router. Ex.: 
// $router.resolve({
//   name: "...",
//   params: { param1: ..., paramN: ... }
// })
export const GetAPIUrl = (options) => {
  const urls = {
    // User APIs
    API_USER_ID: `/user/${options[1]}`,

    // Groups APIs
    API_GROUP_ID: `/group/${options[1]}`,
    API_GROUP_ID_INFO: `/group/${options[1]}/info`,
    API_GROUP_MAKE_ADMIN: `/group/${options[1]}/makeadmin/${options[2]}`,
    API_GROUP_CANCEL_ADMIN: `/group/${options[1]}/canceladmin/${options[2]}`,

    API_GROUP_ID_INVITE: `/group/${options[1]}/Invite`,
    API_GROUP_ID_INVITE_RESEND: `/group/${options[1]}/InviteResend`,
    API_GROUP_ID_REMOVE_ID: `/group/${options[1]}/remove/${options[2]}`,

    API_GROUP_CODEADD_ID: `/Group/JoinCodeAdd?groupId=${options[1]}`,
    API_GROUP_CODEREMOVE_ID: `/Group/JoinCodeRemove?groupId=${options[1]}`,

    // Event APIs
    API_EVENT: `/event/?id=${options[1]}&templateId=${options[2]}&occurrenceIndex=${options[3]}`,
    API_EVENT_ID: `/event/${options[1]}`,
    API_EVENT_ID_INFO: `/event/${options[1]}/info`,
    // ToDo: Back-end - Make sure that 'changeType = 0' if not specified (default)!!
    API_EVENT_UPDATE: `/event/update${ options[1] ? '?changeType=' +  `${options[1]}` : '' }`,  // Optional Query param
    API_EVENT_ID_PUBLISH: `/event/${options[1]}/publish`,
    API_EVENT_ID_CANCEL: `/event/${options[1]}/cancel${ options[2] ? '?changeType=' +  `${options[2]}` : '' }`,
    
    // Post APIs
    API_POST_ID: `/post/${options[1]}`,
    API_POST_ID_PUBLISH: `/post/${options[1]}/publish`,
    API_POST_ID_CANCEL: `/post/${options[1]}/cancel`
  };

  return urls[options[0]];
};
