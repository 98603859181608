import Vue from "vue";
import Vuex from "vuex";
import urls from "@/urls";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import router from "@/router.js";
import { Enums } from "@/common.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {},
    isOpenRSVP: false,
    isLoading: false,
    closeModal: false,
    isDuplicate: false,
    variantPopupConfirm: false,
    helpCard: window.localStorage.getItem("helpCard")
      ? JSON.parse(window.localStorage.getItem("helpCard"))
      : true,
    events: [],
    eventKey: null,
    groups: [],
    group: {},
    notifications: [],
    posts: [],
    post: 0,
    currentFile: {},
    activeModal: Enums.ModalType.NO_MODAL,
    resendInviteForm: null,
    activeSidebar: null,
    activePage: "Events",
    newNotifications: 0,
    minimizedInfo: false,
    openNewGroups: false,
    lockBodyScroll: false,
  },
  getters: {
    getLoadingStatus: (state) => {
      return state.isLoading;
    },
    getHelpStatus: (state) => {
      return state.helpCard;
    },
    getCurrentUser: (state) => {
      return state.currentUser;
    },
    getGroups: (state) => {
      return state.groups;
    },
    getGroup: (state) => {
      return state.group;
    },
    getEvent: (state) => {
      return state.eventKey;
    },
    getActiveModal: (state) => {
      return state.activeModal;
    },
    getActiveSidebar: (state) => {
      return state.activeSidebar;
    },
    getPost: (state) => {
      return state.post;
    },
    getNotifications: (state) => {
      return state.notifications;
    },
    getNewNotifications: (state) => {
      return state.currentUser.countOfNewNotificationMessages;
    },
    getActivePage: (state) => {
      return state.activePage;
    },
    getMinimizedInfo: (state) => {
      return state.minimizedInfo;
    },
    getLockBodyScroll: (state) => {
      return state.lockBodyScroll;
    }
  },
  mutations: {
    changeIsOpenRSVP(state, val) {
      state.isOpenRSVP = val;
    },
    isCloseModal(state, val) {
      state.closeModal = val;
    },
    setVariantPopupConfirm(state, val) {
      state.variantPopupConfirm = val;
    },
    isOpenNewGroups(state, data = Boolean) {
      state.openNewGroups = data;
    },
    setDuplicate(state, val) {
      state.isDuplicate = val;
    },
    setLoadingStatus(state, bool = Boolean) {
      state.isLoading = bool;
    },
    setHelpStatus(state, bool = Boolean) {
      window.localStorage.setItem("helpCard", bool);
      state.helpCard = bool;
    },
    setCurrentUser(state, obj = Object) {
      state.currentUser = { ...obj };
    },
    setGroups(state, arr = Array) {
      state.groups = [...arr];
    },
    setGroup(state, obj = Object) {
      state.group = { ...obj };
    },
    setEvent(state, eventKey = Object) {
      state.eventKey = eventKey;
      
      if (eventKey)
        console.log(`setEvent(...): id: '${state.eventKey.id}', templateId: '${state.eventKey.templateId}', occurrenceIndex: '${state.eventKey.occurrenceIndex}'.`);
      else
        console.log(`setEvent(...): '${eventKey}'.`);
    },
    setModal(state, str = String) {
      state.activeModal = str;
    },
    setResendInvite(state, obj = Object) {
      state.resendInviteForm = obj;
    },
    setSidebar(state, str = String) {
      state.activeSidebar = str;
    },
    setPost(state, id = Number) {
      state.post = id;
    },
    setNotifications(state, arr = Array) {
      state.notifications = [...arr];
    },
    setNewNotifications(state, num = Number) {
      state.newNotifications = num;
    },
    setActivePage(state, str = String) {
      state.activePage = str;
    },
    setMinimizedInfo(state, st = Boolean) {
      state.minimizedInfo = st;
    },
    setLockBodyScroll(state, val) {
      state.lockBodyScroll = val;
    }
  },
  actions: {
    async searchGroup({ commit, state }, options) {
      commit("setLoadingStatus", true);

      // update the number of items if help card is not availabe
      let defaultSize = 9;
      if (state.helpCard === false) {
        defaultSize = 10;
      }

      let url = urls.API_GROUP_ADMIN_SEARCH;
      let data = null;
      data = {
        text: options && options.text ? options.text : "",
        page: options && options.page ? options.page : 1, //0
        pageSize: options && options.pageSize ? options.pageSize : defaultSize
      };

      return new Promise((resolve, reject) => {
        this._vm
          .$http({
            url,
            data,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
            }
          })
          .catch((error) => {
            commit("setLoadingStatus", false);
            reject(error);
          })
          .then((response) => {
            commit("setLoadingStatus", false);
            commit("setGroups", [response.data]);
            if (response.data.pageItemsCount === 0 && state.openNewGroups) {
              commit("setModal", Enums.ModalType.ADD_GROUP);
              commit("isOpenNewGroups", false);
            }
            setTimeout(() => resolve(response), 200);
          });
      });
    },

    async loadCurrentUser({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm
          .$http({
            url: urls.API_USER_PROFILE,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
            }
          })
          .catch((error) => {
            if (error.response?.status !== 200) {
              this.dispatch("userLogout");
              this.dispatch("setFetchStatus", false);
            }
            
            reject(error);
          })
          .then((response) => {
            if (response?.status === 200) {
              commit("setCurrentUser", response.data);
            }
            
            resolve(response);
          });
      });
    },
    setCurrentEvent({ commit }, eventKey = Object) {
      commit("setEvent", eventKey);
    },
    userLogout({ dispatch }) {
      dispatch("userLogoutNotRedirect");
    },
    userLogoutNotRedirect({ commit }, redirect = true) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          Vue.$cookies.remove("userEventAppToken");
          commit("setGroup", {});
          commit("setGroups", []);
          commit("setEvent", 0);
          commit("setPost", 0);
          commit("setCurrentUser", {});
          if(redirect) {
            router.push({ name: "SignIn" });
          }
          Vue.notify({
            group: "hint",
            title: "Goodbye",
            text: "See you later!",
            type: "success",
          });
        })
        .catch((error) => {
          Vue.notify({
            group: "hint",
            title: "Oops",
            text: error,
            type: "error"
          });
        });
    },
    setActiveModal({ commit }, type) {
      commit("setModal", type);
    },
    setResendInvite({ commit }, form) {
      commit("setModal", Enums.ModalType.INVITE);
      commit("setResendInvite", form);
    },
    setActiveSidebar({ commit }, type) {
      commit("setSidebar", type);
    },
    clearGroup({ commit }) {
      commit("setGroup", {});
    },
    setCurrentPost({ commit }, id) {
      commit("setPost", id);
    },
    setFetchStatus({ commit }, status) {
      commit("setLoadingStatus", status);
    },
  },
  modules: {}
});
